<template>
  <div class="mt-2">
    <b-link class="p-2 p-sm-3">
      <b-img
        :src="blankPageLogo"
        alt="logo"
      />
    </b-link>

    <div class="p-2 p-sm-3">
      <div class="w-100">
        <h2 class="mb-1">
          Privacy Policy
        </h2>
        <p>
          <span
            style="font-weight: 400;"
          >Dengan baik kami menyadari untuk penting-nya informasi yang
            diberikan pengguna kedalam aplikasi NikahSaja maka platform
            nikahsaja akan memberikan informasi tentang kebijakan privasi supaya
            pengguna memahami ketentuan berikut:</span>
        </p>
        <ul>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">Siapa Kami</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">Informasi yang kami kumpulkan</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Menggunakan teknologi Cookies atau serupa lainnya</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Cara menggunkan Informasi yang diberikan oleh pengguna</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Cara kami membagikan informasi</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">Hak Pengguna</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Berapa lama kami menyimpan data pengguna</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">Perubahan Kebijakan Privacy</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">Cara menghubungi kami</span>
          </li>
        </ul>
        <p><b>Siapa Kami</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Nikah Saja merupakan layanan perjodohan online terbaik di
            Indonesia. Mesin kami akan membantu Anda menemukan orang yang
            kompatibel melalui algoritma perjodohan kami, yang tentunya sudah
            disesuaikan berdasarkan preferensi / kriteria yang Anda cari dalam
            sebuah pasangan. Di Nikah Saja mengajak anda dan orang-orang single
            lainnya untuk menciptakan hubungan yang bermakna dan membuat
            hubungan yang memiliki tujuan.</span>
        </p>
        <p><b>Informasi yang kami kumpulkan</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Jelas kami tidak dapat membantu anda menemukan pasangan yang
            menurut anda baik untuk menjadi pasangan sesuai dengan keriteria
            anda yang diinginkan, apabila tidak memberikan beberapa informasi
            tetang diri anda. Berikut informasi yang anda berikan kepada
            kami</span>
        </p>
        <ul>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Alamat Email, diperlukan saat mendaftar di platform
              nikahsaja</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Ketika melengkapi profil, Anda dapat membagikan informasi
              tambahan kepada kami, seperti detail pada bio, minat, dan detail
              lainnya tentang Anda, serta konten seperti foto.&nbsp; Anda
              mungkin mengizinkan kami mengakses kamera atau album foto
              Anda.</span>
          </li>
        </ul>
        <p><b>Menggunakan Teknologi Cookies atau serupa lainnya</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Kami menggunkan cookies untuk operasional mengaman akeses yang
            lebih mudah untuk menyimpan data pengenal di browser dan perangkat
            seluler, juga beberapa angka dan karakter acak untuk
            preferensi&nbsp; data anda dan data lainnya.</span>
        </p>
        <p>
          <span
            style="font-weight: 400;"
          >Kami menggunakan geolocation yang secara fungsi untuk memberikan
            informasi lokasi anda, mungkin anda mengijikan perangkat seluler
            anda untuk mengaktifkan fitur ini.</span>
        </p>
        <p><b>Cara menggunkan Informasi yang diberikan oleh pengguna</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >kami menggunakan informasi Anda adalah untuk memberikan dan
            meningkatkan layanan kami dan juga dapat menampilkan iklan yang
            mungkin menarik bagi anda, selain ini alasan kami untuk data anda
            aman.&nbsp; beberapa contoh praktisnya.</span>
        </p>
        <ul>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span style="font-weight: 400;">untuk mengelola akun anda</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >untuk membantu anda terhubung dengan pengguna lainnya</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >untuk meningkatkan layanan kami dan mengembangkan layanan
              baru</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >mencegah, melawan dan mendeteksi penipuan atau kegiatan ilegal
              lainnya</span>
          </li>
        </ul>
        <p><b>Cara kami membagikan informasi</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Tujuan kami ingin membantu anda mendapatkan pasangan atau hubungan
            yang lebih ber makna, tentu saja yang paling utama mendapatkan
            informasi seputar anggota adalah anggota lainnya. Kami juga
            membagikan beberapa informasi anggota kepada penyedia layanan dan
            mitra yang membantu kami mengoperasikan layanan, dalam kasus
            tertentu, kami juga dapat membagikan informasi anggota kepada aparat
            hukum yang berwenang sesuai dengan ketentuan hukum yang
            berlaku.</span>
        </p>
        <p><b>Hak Pengguna</b></p>
        <ul>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Mengakses dan Memperbaharui di layanan, pengaturan yang dimana
              pengguna berhak menghapus informasi yang sudah diberikan kepada
              kami.</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Izikan perangkat, Pengguna berhak untuk mengizikan atau tidak di
              pengaturan aktif nofitikasi dan juga berkas (album foto) yang
              perlu izin dari pengguna</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Hapus dan Instalasi, Anda berhak untuk menghentikan atau
              menghapus aplikasi nikahsaja dari perangkat seluler anda.</span>
          </li>
          <li
            style="font-weight: 400;"
            aria-level="1"
          >
            <span
              style="font-weight: 400;"
            >Penutup Akun, Anda dapat menghapus akun Anda dengan menggunakan
              fungsionalitas terkait secara langsung di layanan.</span>
          </li>
        </ul>
        <p><b>Berapa lama kami menyimpan data pengguna</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Kami hanya menyimpan data anda selama yang kami perlukankan dan
            untuk pengembangan layanan dan tujuan bisnis yang sah. Jika anda
            memutuskan untuk menutup akun maka informasi yang sudah anda bagikan
            tidak akan terlihat oleh pengguna lain, dan apabila sudah tidak anda
            aktifkan kembali selama setahun. Akun anda secara permanen akan
            terhapus.</span>
        </p>
        <p><b>Perubahan Kebijakan Pribadi</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Karena kami selalu mencari cara yang baru dan inovatif untuk
            membantu Anda menjalin hubungan yang bermakna dan berupaya
            memastikan penjelasan tentang praktik data kami tetap akurat,
            kebijakan ini dapat berubah seiring waktu. Kami akan memberi tahu
            Anda sebelum diberlakukannya perubahan yang signifikan sehingga Anda
            memiliki waktu untuk meninjau perubahan tersebut.</span>
        </p>
        <p><b>Cara menghubungi kami</b></p>
        <p>
          <span
            style="font-weight: 400;"
          >Jika memiliki pertanyaan tentang Kebijakan Privasi ini, terdapat
            beberapa opsi yang berbeda di bawah ini:</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import { useTitle } from '@vueuse/core'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const { appName, logo, logoSm } = $themeConfig.app

    useTitle(`Privacy Policy - ${appName}`)

    const blankPageLogo = computed(() => {
      if (store.getters['app/currentBreakPoint'] === 'xs') {
        return logoSm
      }

      return logo
    })

    return {
      appName,
      blankPageLogo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

@media (min-width: 768px) {
}
</style>
